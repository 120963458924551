import React from 'react';
import style from './Table.module.css';
import { StateContext } from './StateProvider';

export default function Table () {

  const { state: { table }, dispatch } = React.useContext(StateContext);

  return (
    <div className={style.root} data-show={table.length !== 0}>
      <div 
        className={style.background}
        onClick={() => dispatch({ type: 'hide-table' })}
      ></div>
      <div className={style.tableContainer}>
        <table className={style.table}>
          <thead>
            <tr>
              {table[0] && table[0].map((cell, index) => (
                <th key={index}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.map((row, index) => index !== 0 && (
              <tr key={index}>
                {row.map((cell, index) => (
                  <td key={index}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}