import React from "react";
import style from './Log.module.css';
import { StateContext } from './StateProvider';

export default function Log () {
  const { state } = React.useContext(StateContext);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [state.logs, ref]);

  return (
    <ul ref={ref} className={style.root}>
      {state.logs.map((log, i) => (
        <li key={i} className={style.log} data-type={log.type}>
          {log.message}
        </li>
      ))}
    </ul>
  )
}