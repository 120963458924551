import React from 'react';
import { StateContext } from './StateProvider';
import DropArea from './DropArea';
import Log from './Log';

export default function Header () {

  const { state } = React.useContext(StateContext);

  return state.workbook 
    ? <Log />
    : <DropArea />
}