import React from 'react';
import style from './DropArea.module.css';
import * as XLSX from 'xlsx';
import { StateContext } from './StateProvider';

export default function DropArea() {

  const { dispatch } = React.useContext(StateContext);

  React.useEffect(() => {
    document.ondragover = document.ondrop = ev => ev.preventDefault();

    return () => {
      document.ondragover = document.ondrop = null;
    }
  }, []);

  const handleFileLoaded = async (event) => {
    const workbook = XLSX.read(event.target.result, { 
      type: 'binary',
      // cellNF: true,
    }); 
    dispatch({ type: 'register-workbook', payload: workbook });
    dispatch({ type: 'push-log', payload: { type: 'success', message: 'ファイルを読み込みました' } })
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length !== 1) return;
    const fileReader = new FileReader();
    fileReader.addEventListener('load', handleFileLoaded);
    fileReader.readAsBinaryString(files[0]); 
  }

  const handleChangeFileInput = (event) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', handleFileLoaded);
    fileReader.readAsBinaryString(event.target.files[0]); 
  }

  return (
    <div className={style.root} 
      onDrop={handleFileDrop}
    >
      <div className={style.inner}>
        <h3>データをこちらにドラッグ&ドロップ</h3>
        <label>
          <span>もしくは、</span>
          <input type="file" name="file" id="droparea__input"
            onChange={handleChangeFileInput}
          />
        </label>
      </div>
    </div>
  )
}