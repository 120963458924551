import './style.css';
import style from './App.module.css';
import { StateProvider } from './StateProvider';
import Header from './Header';
import Column from './Column';
import Table from './Table';
import Tab from './Tab';

function App() {

  return (
    <StateProvider>
      <div className={style.root}>
        <div className={style.tab}>
          <Tab />
        </div>
        <div className={style.header}>
          <Header />
        </div>
        <div className={style.content}>
          <div className={style.daily}>
            <Column title="日別数値" type="daily" />
          </div>
          <div className={style.keyword}>
            <Column title="キーワード別" type="keyword" />
          </div>
          <div className={style.adgroup}>
            <Column title="広告グループ別" type="adgroup" />
          </div>
        </div>
        <Table />
      </div>
    </StateProvider>
  );
}

export default App;
