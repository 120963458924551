import React from 'react';
import { StateContext } from './StateProvider';
import style from './Tab.module.css';
import { type } from '@testing-library/user-event/dist/type';

export default function Tab () {

  const { state, dispatch } = React.useContext(StateContext);

  return (
    <div className={style.root}>
      <button 
        data-active={state.setting.label === 'smartphone'}
        onClick={() => dispatch({ type: 'change-setting', payload: 'smartphone' })}
      >スマホ年金</button>

      <button
        data-active={state.setting.label === 'dt3'}
        onClick={() => dispatch({ type: 'change-setting', payload: 'dt3' })}
      >DT+3商品</button>
    </div>
  )
}