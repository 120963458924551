import React from 'react';
import { StateContext } from './StateProvider';

/**
 * 
 * @param { XLSX.Workbook } workbook 
 * @param { Object } target 
 * @param { String } target.name
 * @param { String } target.cols - e.g. C,D,E,J,AA
 * @param { Number } target.startRow
 * @param { Object[] } target.appends - e.g. { value: 'Yahooサーチ', position: 0 }
 * @param { Boolean } useIndex
 * @param { String[] } header
 * @param { String } title
 */
export default function useExtract(workbook, targets, header, useIndex, title) {

  const [extracted, setExtracted] = React.useState([]);
  const [tsv, setTsv] = React.useState('');
  const { dispatch } = React.useContext(StateContext);

  const extractDataFromWorkbook = (workbook, target) => {

    const sheet = workbook.Sheets[target.name];

    if (!sheet) {
      dispatch({ type: 'push-log', payload: { type: 'error', message: `[${target.name}] シートが見つかりませんでした。` }})
      return [];
    }

    const cols = target.cols.replace(' ', '').split(',');

    const result = [];
    let currentRow = target.startRow;

    while(currentRow !== 0) {

      const rowData = [];

      cols.forEach(col => {
        const index = `${col}${currentRow}`;
        const cell = sheet[index];
        const data = convertCellToData(cell);
        rowData.push(data);
      });

      if (rowData.every(cell => cell === undefined)) {
        dispatch({ type: 'push-log', payload: { type: 'info', message: `[${target.name}] 行番号${currentRow}にて取得処理を完了しました。` }})
        currentRow = 0;
      } else {

        let enablePush = true;

        // フィルター処理
        if (
          target.targetCol 
          && target.targetValue
          && !target.targetValue.includes(rowData[cols.indexOf(target.targetCol)])
        ) {
          enablePush = false;
        }

        // 追加カラムの挿入
        if (target.appends) {
          target.appends.forEach((append) => {
            rowData.splice(append.position, 0, append.value);
          });
        }

        if (enablePush) {
          result.push(rowData);
        }

        currentRow = currentRow + 1;

        // 行データの確認
        if (rowData.some(cell => cell === undefined)) {
          dispatch({ type: 'push-log', payload: { type: 'warn', message: `[${target.name}] 行番号${currentRow}にて一部の値が空でした。` }})
        }
      }
    }

    return result;
  }

  React.useEffect(() => {
    if (!workbook) return;
    const result = targets.flatMap(target => extractDataFromWorkbook(workbook, target));
    if (useIndex) result.map((row, index) => row.unshift(index + 1));

    // 列に対しての書式チェック
    // const isValidCols = result[0].every((_, i) => {
    //   const cols = result.map(row => row[i]);
    //   return cols.reduce((prev, current) => {
    //     if (current === null || typeof prev !== typeof current) {
    //       return null;
    //     }
    //     return current;
    //   }, cols[0]) !== null;;
    // });

    // if (!isValidCols) {
    //   dispatch({ type: 'push-log', payload: { type: 'error', message: `[${title}] 書式が一致しない列が存在しました。` }})
    // }
    setTsv(extractedToTsx(result));

    if (header) result.unshift(header);
    setExtracted(result);

  }, [workbook, useIndex, targets, header]);

  return { extracted, tsv };
}

const extractedToTsx = (extracted) => {
  return extracted.map(row => row.join('\t')).join('\n');
}


const convertCellToData = (cell) => {
  if (cell === undefined) return undefined;

  // 数値以外はそのまま
  if (cell.t !== 'n') return cell.v;
  if (cell.w === undefined) return cell.v;

  // 実数値が整数かつ表示数値に小数点が含まれる場合は何もしない
  if (cell.w.includes('.') && !cell.v.toString().includes('.')) return cell.w;

  let payload = Number(cell.v);

  if (cell.w.includes('%'))
    payload = payload * 100;

  if (cell.w.includes(','))
    payload = payload.toLocaleString();

  if (cell.w.includes('%'))
    payload = `${payload}%`;

  if (cell.w.includes('￥'))
    payload = `￥${payload}`;

  return payload;
}